<template>
  <main class="body">
    <input id="home" type="radio" name="menu" checked="checked" />
    <section class="excludeZoom">
      <h1>MEL</h1>
      <h4>Territory</h4>
    </section>

    <input id="quote" type="radio" name="menu" />
    <section>
      <article>
        <span>
          <h3>Quote</h3>
        </span>
        <span>
          <h1>engineering<br />degrees.</h1>
        </span>
        <span>
          <h4>honestly<br />they are</h4>
          <h2>everywhere</h2>
        </span>
        <span>
          <h5>It turns out that&nbsp;</h5>
          <h6>creativity</h6>
          <h5>&nbsp;and&nbsp;</h5>
          <h4>&nbsp;knowledge</h4>
        </span>
        <span>
          <p><b>are&nbsp;</b>&nbsp;the secret ingredients</p>
          <h5>for me to</h5>
          <p><b>deliver <u><s>amazing</s></u> products.</b></p>
        </span>
        <span>
          <h2>HOWEVER.</h2>
        </span>
        <span>
          <p>completely <em>understand</em></p>
          <p><b>each of the component</b></p>
          <p>before execution</p>
        </span>
        <span>
          <!-- <h2></h2> -->
          <h1>makes</h1>
          <h4>a difference between<br /><b><u>success</u> and failure</b>.</h4>
        </span>
      </article>
    </section>

    <input id="more" type="radio" name="menu" />
    <section>
      <article>
        <span>
          <h3>MORE?!</h3>
        </span>
        <span>
          <h1>KEEP</h1>
          <h2>looking</h2>
          <h4>you're<br />there.</h4>
        </span>
        <span>
          <h4>besides looking here<br />you can leverage on</h4>
          <h2>Google</h2>
        </span>
        <span>
          <h6><b>it definitely has</b> (a lot moreee)</h6>
          <h4>amazing</h4>
        </span>
        <span>
          <h1>information</h1>
        </span>
        <span>
          &nbsp;<h2>stories</h2></span>
        <span>
          <h1>&amp;</h1>
          <p><em>&nbsp;<u>about m</u>e.</em></p>
        </span>
      </article>
    </section>

    <input id="contact" type="radio" name="menu" />
    <section>
      <article>
        <span>
          <h3>Contact</h3>
        </span>
        <span>
          <h2>oh...</h2>
          <h1>yeah...</h1>
          <h4><em>about<br />that...</em></h4>
        </span>
        <span>
          <h5><em>It is</em></h5>
          <h4>&nbsp;located somewhere&nbsp;</h4>
          <h3><b>amazing</b></h3>
        </span>
        <span>
          <h6><em>WITHIN THIS&nbsp;</em> <u><s>website</s></u></h6> <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 320">
            <g>
              <path class="orange"
                d="m320.332 174.25c0 0 53.59 105.839 162.621 105.608 145.539-.309 141.489-239.6394-4.525-239.853-130.462-.1909-169.6 234.495-300.193 239.853-172.94203 7.095-167.6333-237.2565-4.526-239.853 101.036-1.6084 146.623 134.245 146.623 134.245"
                fill="none" stroke="#f7b37f" stroke-linecap="miter" stroke-width="60" stroke-miterlimit="2" />
              <path class="white"
                d="m320.332 174.25c0 0 53.59 105.839 162.621 105.608 145.539-.309 141.489-239.6394-4.525-239.853-130.462-.1909-169.6 234.495-300.193 239.853-172.94203 7.095-167.6333-237.2565-4.526-239.853 101.036-1.6084 146.623 134.245 146.623 134.245"
                fill="none" stroke="#fff" stroke-linecap="miter" stroke-width="60" stroke-miterlimit="2" />
              <path class="black"
                d="m320.332 174.25c0 0 53.59 105.839 162.621 105.608 145.539-.309 141.489-239.6394-4.525-239.853-130.462-.1909-169.6 234.495-300.193 239.853-172.94203 7.095-167.6333-237.2565-4.526-239.853 101.036-1.6084 146.623 134.245 146.623 134.245"
                fill="none" stroke="#3f3f37" stroke-linecap="miter" stroke-width="63" stroke-miterlimit="2" />
            </g>
          </svg>
        </span>
        <p>(Look at WHO'S MEL)</p>
      </article>
    </section>

    <input id="mel" type="radio" name="menu" />
    <section>
      <article>
        <span>
          <h3>Who's Mel??</h3>
        </span>
        <span>
          <h2>That's me!</h2>
        </span>
        <span>
          <h2><u><s>I'm</s></u> a</h2>
          <h5><s>full stack</s><br /><em>engineer</em></h5>
        </span>
        <span>
          <h1>from</h1>
          <h4><u><b>Planet</b></u><br />Earth</h4>
          <h2>who</h2>
        </span>
        <span>
          <h2>(</h2>
          <h4>loves new<br />challenges</h4>
          <h2>)</h2>
          <h5>likes to spend his time<br /><b>solving</b> <u><s>problems</s></u></h5>
        </span>
        <span>
          <h4>check out <b>my</b></h4>
          <h6><a href="https://go.melvinchng.com/linkedin" target="_blank"><s>profile</s></a></h6><u>
            <h5>and let's connect.</h5>
          </u>
        </span>
      </article>
    </section>

    <label for="menu">menu</label>
    <input type="radio" name="menu" id="menu" />
    <menu>
      <nav>
        <label for="home">home</label>
        <label for="mel">who's mel??</label>
        <label for="quote">quote</label>
        <label for="contact">contact</label>
        <label for="more">more?!</label>
      </nav>
    </menu>
  </main>
</template>

<style scoped>
/*------------∞
|  reset.css  |
∞------------*/
  /* ∞∞ postcss ∞∞ */

  /* ∞∞ font ∞∞ */
  @import url(https://fonts.googleapis.com/css?family=Lato:300,900);

  @media screen and (max-width: 992px), screen and (max-height: 800px) {
    section:not(.excludeZoom) {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 800px), screen and (max-height: 600px) {
    section:not(.excludeZoom) {
      zoom: 0.6;
    }
  }

  @media screen and (max-width: 600px), screen and (max-height: 400px) {
    section:not(.excludeZoom) {
      zoom: 0.45;
    }
  }

  @media screen and (max-width: 400px), screen and (max-height: 200px) {
    section:not(.excludeZoom) {
      zoom: 0.3;
    }
  }

  /* ∞∞ global ∞∞ */
  * {
    position: relative;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border-color: inherit;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .body {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 100%;
    font-style: normal;
    font-weight: 300;
    line-height: 1;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
  }

  /* ∞∞ text styling ∞∞ */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  em,
  a,
  b,
  strong {
    font-style: normal;
    font-weight: 300;
  }

  strong {
    font-size: 1.15em;
  }

  small {
    font-size: .85em;
  }

  ul,
  ol,
  menu {
    list-style: none;
  }

  sub,
  sup {
    font-size: .75em;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  abbr[title] {
    border-bottom: 1px dotted;
  }

  dfn {
    font-style: italic;
  }

  /* ∞∞ form elements ∞∞ */
  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
    margin: 0;
    background: transparent;
    outline: none;
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  button,
  select {
    text-transform: none;
    cursor: pointer;
  }

  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
    min-width: 0;
  }

  textarea {
    resize: none;
  }

  /* ∞∞ link styling ∞∞ */
  a {
    display: inline;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  /* a::before {
		width: 100%;
		height: .2rem;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: #7ac;
	}
	a:hover::before {
		background-color: #49e;
		height: .35rem;
	} */

  /* ∞∞ hr using box ∞∞ */
  hr {
    border: none;
    box-sizing: border-box;
    height: .075rem;
  }

  /* ∞∞ hidden ∞∞ */
  [hidden],
  template {
    display: none;
  }

  /* ∞∞ media specific ∞∞ */
  audio:not([controls]) {
    display: none;
    height: 0;
  }

  /* ∞∞ tables ∞∞ */
  table {
    border-collapse: collapse;
    border-spacing: 0;
    border: none;
  }

  td,
  th {
    margin: 0;
    padding: 0;
    font-weight: inherit;
    text-align: inherit;
  }


  /*====================================================== ∞∞ browser specific ∞∞ */

  /* ∞∞ IE ∞∞ */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }

  audio,
  canvas,
  progress,
  video {
    display: inline-block;
    vertical-align: baseline;
  }

  a {
    background-color: transparent;
  }

  button {
    overflow: visible;
  }

  html {
    -ms-text-size-adjust: 100%;
  }

  img {
    border: none;
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  legend {
    border: none;
    padding: 0;
  }

  mark {
    background: #ff0;
    color: #000;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  textarea {
    overflow: auto;
  }

  /* ∞∞ moz ∞∞ */
  .body {
    -moz-osx-font-smoothing: grayscale;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: none;
    padding: 0;
  }

  input {
    line-height: normal;
  }

  /* ∞∞ webkit ∞∞ */
  * {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
  }

  html {
    -webkit-text-size-adjust: 100%;
  }

  .body {
    -webkit-font-smoothing: antialiased !important;
  }

  blockquote {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
  }

  fieldset {
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-before: 0;
    -webkit-padding-start: 0;
    -webkit-padding-end: 0;
    -webkit-padding-after: 0;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    height: auto;
  }

  input[type="search"] {
    -webkit-appearance: textfield;
  }

  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  progress {
    -webkit-appearance: none;
  }

  /*	RADIO-MENU STUFF
   ∞∞ STARTS HERE ∞∞
*/
  .body {
    background-image: linear-gradient(to bottom right, #ba532e, #ff934c 90%)
  }

  main {
    width: 100%;
    height: 100%;
    color: white;
    font-weight: 300;
  }

  main input[name='menu'] {
    display: none
  }

  /* sections */
  /* keep them off canvas */
  section {
    transition: bottom 0ms linear 900ms, opacity 900ms cubic-bezier(.4, .25, .3, 1);
    width: 100%;
    height: 100%;
    position: fixed; /* absolute */
    bottom: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  section:first-of-type {
    justify-content: center
  }

  section article {
    min-height: 740px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  section article span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    white-space: nowrap;
  }

  /* menu */
  /* this goes off canvas as well */
  menu {
    transition: bottom 0ms linear 1000ms, background-color 1000ms cubic-bezier(.4, .25, .3, 1);
    width: 100vw;
    height: 100vh;
    position: fixed;
    bottom: 100%;
    left: 0;
    color: #3f3f37;
    background-color: hsla(0, 0%, 100%, 0);
  }

  menu nav {
    transition: all 500ms cubic-bezier(.4, .25, .3, 1) 250ms;
    width: 100%;
    height: 250%;
    position: absolute;
    bottom: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: .5rem;
  }

  menu nav label {
    line-height: 40px;
    text-transform: uppercase;
    cursor: pointer;
  }

  menu nav label::before,
  menu nav label::after {
    content: '';
    transition: all 300ms cubic-bezier(.4, .25, .3, 1);
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    background-color: #f7b37f;
    opacity: 0;
  }

  menu nav label::before {
    top: -.5rem
  }

  menu nav label::after {
    bottom: -.5rem
  }

  menu nav label:hover::before,
  menu nav label:hover::after {
    opacity: 1
  }

  menu nav label:hover::before {
    top: 0
  }

  menu nav label:hover::after {
    bottom: 0
  }


  /* menu button */
  label[for='menu'] {
    transition: opacity 300ms cubic-bezier(.4, .25, .3, 1) 900ms;
    display: inline-block;
    position: fixed;
    top: 30px;
    left: 100px;
    line-height: 40px;
    font-size: 1.3rem;
    font-weight: 900;
    text-transform: uppercase;
    cursor: pointer;
  }

  label[for='menu']::before,
  label[for='menu']::after {
    content: '';
    transition: all 300ms cubic-bezier(.4, .25, .3, 1);
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    background-color: white;
  }

  label[for='menu']::before {
    top: 0
  }

  label[for='menu']::after {
    bottom: 0
  }

  label[for='menu']:hover::before {
    top: -.25rem
  }

  label[for='menu']:hover::after {
    bottom: -.25rem
  }

  /* menu toggle */
  /* click the label > checks the radio > toggles the menu */
  #menu:checked+menu {
    transition: background-color 800ms cubic-bezier(.4, .25, .3, 1);
    bottom: 0;
    background-color: hsla(0, 0%, 100%, 1);
  }

  #menu:checked+menu nav {
    transition: all 500ms cubic-bezier(.4, .25, .3, 1) 250ms;
    height: 70%;
    bottom: 15%;
    font-size: 1.7rem;
  }

  /* section change */
  /* click the label > check the radio > change the section */
  input[name="menu"]:checked+section {
    transition: opacity 900ms cubic-bezier(.4, .25, .3, 1);
    bottom: 0;
    opacity: 1;
  }

  /*	RADIO-MENU STUFF
    ∞∞ ENDS HERE ∞∞
*/

  /* the rest is just content */
  h1,
  h2,
  h3,
  h4,
  h5,
  em,
  p,
  b {
    font-weight: 900
  }

  h1,
  h4,
  h6,
  b {
    text-transform: uppercase
  }

  h2,
  h6,
  b {
    color: #3f3f37
  }

  h1,
  h5 {
    color: #f7b37f
  }

  h5,
  h6 {
    font-size: 2.2rem
  }

  em {
    font-size: 1.2em
  }

  h1 {
    font-size: 6.9rem
  }

  h2 {
    font-size: 6.4rem
  }

  h4 {
    font-size: 2.45rem
  }

  p {
    font-size: 1.5rem
  }

  h3 {
    font-size: 3.25rem;
    text-decoration: underline;
  }

  s {
    text-decoration: none;
    color: white;
    font-size: 1.1em;
  }

  /* landing */
  section:first-of-type h1 {
    margin-top: -12px
  }

  section:first-of-type h2 {
    margin-top: -38px
  }

  section:first-of-type h4 {
    margin-top: -5px;
  }

  section:first-of-type h4::after {
    content: '';
    width: 100%;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
  }

  /* how */
  section:nth-of-type(2) h4 {
    margin-bottom: -2px
  }

  section:nth-of-type(2) span:nth-child(2) h1 {
    margin-left: -5px;
    line-height: .79;
  }

  section:nth-of-type(2) span:nth-child(2) h1::after {
    content: '';
    width: 99%;
    height: 4px;
    position: absolute;
    bottom: -10px;
    left: 5px;
    background-color: white;
  }

  section:nth-of-type(2) span:nth-child(3) {
    margin: -20px 0 15px;
  }

  section:nth-of-type(2) span:nth-child(3) h2 {
    margin-bottom: 13px
  }

  section:nth-of-type(2) span:nth-child(3) h4 {
    margin-bottom: 20px;
    line-height: .79;
  }

  section:nth-of-type(2) span:nth-child(5) {
    margin-bottom: 35px;
  }

  section:nth-of-type(2) span:nth-child(5) h5 {
    margin-bottom: -2px
  }

  section:nth-of-type(2) span:nth-child(6) {
    margin: -10px 0 5px;
  }

  section:nth-of-type(2) span:nth-child(6) h4 {
    margin-bottom: 13px
  }

  section:nth-of-type(2) span:nth-last-child(2) {
    margin-top: -13px
  }

  section:nth-of-type(2) span:last-child {
    margin-top: -15px;
  }

  section:nth-of-type(2) span:last-child h4 {
    margin-bottom: 10px
  }

  /* why */
  section:nth-of-type(3) h1,
  section:nth-of-type(3) h4,
  section:nth-of-type(3) h5 {
    line-height: .79
  }

  section:nth-of-type(3) h1 {
    margin-left: -5px
  }

  section:nth-of-type(3) h2 {
    margin-bottom: -10px
  }

  section:nth-of-type(3) h5 {
    margin-bottom: 5px
  }

  section:nth-of-type(3) h6 {
    margin: 0 0 -2px -3px
  }

  section:nth-of-type(3) span:nth-child(3) {
    margin-top: -13px
  }

  section:nth-of-type(3) span:nth-child(4) {
    margin-top: 15px
  }

  section:nth-of-type(3) span:nth-child(5) {
    margin-top: 20px;
  }

  section:nth-of-type(3) span:nth-child(5) h1::after {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    top: -3px;
    left: 0;
    background-color: white;
  }

  section:nth-of-type(3) span:nth-child(6) {
    margin-top: -25px
  }

  section:nth-of-type(3) span:last-child {
    margin: -20px -28px 0 0;
    justify-content: center;
  }

  section:nth-of-type(3) span:last-child p {
    margin: 0 0 -3px -3px;
    font-size: 3.8rem;
  }

  /* contact */
  section:nth-of-type(4) span:first-child {
    margin-bottom: -10px
  }

  section:nth-of-type(4) h1 {
    margin-left: -30px
  }

  section:nth-of-type(4) span:nth-child(2) h4 {
    margin-bottom: 8px;
    line-height: .9;
  }

  section:nth-of-type(4) span:last-of-type h6 {
    transform: translateY(-125%)
  }

  section:nth-of-type(4) p:last-child {
    margin-right: 30px
  }

  section:nth-of-type(4) svg {
    width: 300px;
    height: auto;
    margin-top: 20px;
  }

  section:nth-of-type(4) svg path {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }

  section:nth-of-type(4) svg path.black {
    -webkit-animation: draw 5s cubic-bezier(.4, .25, .3, 1) infinite;
    animation: draw 5s cubic-bezier(.4, .25, .3, 1) infinite;
    stroke-dashoffset: 0;
    stroke-dasharray: 1569.27294921875;
  }

  section:nth-of-type(4) svg path.orange {
    -webkit-animation: pathA 20s linear infinite;
    animation: pathA 20s linear infinite;
    opacity: 0;
  }

  section:nth-of-type(4) svg path.white {
    -webkit-animation: pathB 20s linear infinite;
    animation: pathB 20s linear infinite;
    opacity: 0;
  }

  input[name="menu"]:checked+section path {
    -webkit-animation-play-state: running;
    animation-play-state: running
  }

  /* mel */
  section:last-of-type span:nth-child(3) {
    margin-top: -15px;
  }

  section:last-of-type span:nth-child(3) h2 {
    margin-left: 8px
  }

  section:last-of-type span:nth-child(3) h5 {
    margin: 0 240px 10px 0;
    line-height: .8;
  }

  section:last-of-type span:nth-child(4) h4 {
    margin: 0 0 7px 15px;
    line-height: 1.2;
  }

  section:last-of-type span:nth-child(6) {
    margin-top: 35px;
  }

  section:last-of-type span:nth-child(6) u {
    margin-bottom: 4px
  }

  /* animations */
  @-webkit-keyframes draw {
    0% {
      stroke-dashoffset: 0
    }

    100% {
      stroke-dashoffset: 3138.54589844
    }
  }

  @keyframes draw {
    0% {
      stroke-dashoffset: 0
    }

    100% {
      stroke-dashoffset: 3138.54589844
    }
  }

  @-webkit-keyframes pathA {
    0% {
      opacity: 0
    }

    24% {
      opacity: 0
    }

    25% {
      opacity: 1
    }

    99% {
      opacity: 1
    }

    100% {
      opacity: 0
    }
  }

  @keyframes pathA {
    0% {
      opacity: 0
    }

    24% {
      opacity: 0
    }

    25% {
      opacity: 1
    }

    99% {
      opacity: 1
    }

    100% {
      opacity: 0
    }
  }

  @-webkit-keyframes pathB {
    0% {
      opacity: 0
    }

    49% {
      opacity: 0
    }

    50% {
      opacity: 1
    }

    74% {
      opacity: 1
    }

    75% {
      opacity: 0
    }

    100% {
      opacity: 0
    }
  }

  @keyframes pathB {
    0% {
      opacity: 0
    }

    49% {
      opacity: 0
    }

    50% {
      opacity: 1
    }

    74% {
      opacity: 1
    }

    75% {
      opacity: 0
    }

    100% {
      opacity: 0
    }
  }
</style>

<script>
  // document.addEventListener('contextmenu', event => event.preventDefault());

  document.addEventListener('keyup', (e) => {
    if (e.key == 'PrintScreen') {
      navigator.clipboard.writeText('');
    }
  });

  document.addEventListener('keydown', (e) => {
    if (e.ctrlKey && e.key == 'p') {
      e.cancelBubble = true;
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  });
</script>`